import { Injectable } from "@angular/core";
import { BfcGoogleAnalyticsService } from "@bfl/components/google-analytics";
import { BfcTranslationService } from "@bfl/components/translation";
import { BfcConfigurationService } from "@bfl/components/configuration";

@Injectable()
export class InitializerProvider {

  constructor(
    private bfcTranslationService: BfcTranslationService,
    private bfcGoogleAnalyticsService: BfcGoogleAnalyticsService,
    private bfcConfigurationService: BfcConfigurationService,
  ) {
  }

  public initialize() {
    this.bfcGoogleAnalyticsService
      .initGoogleTagManager(this.bfcConfigurationService.configuration.googleTagManagerContainerId);

    const globalNavElements = document.getElementsByTagName("bfe-global-navigation");
    const isBkw: boolean = this.bfcConfigurationService.environment.theme === "bkw";

    const serviceItemsModify = JSON.stringify([
      {
        key: "contact",
        titleTranslatable: this.bfcTranslationService.translate("SERVICE_NAV.CONTACT"),
        url: this.bfcConfigurationService.configuration.globalContactURL,
        hide: !isBkw,
      },
      {
        key: "jobs",
        hide: true,
      }, 
    ]);
    if (globalNavElements.length === 1) {
      globalNavElements[0].setAttribute("service-items-modify", serviceItemsModify);
    } else {
      throw (new Error("too many or no global navigation element found"));
    }

    const footerElements = document.getElementsByTagName("bfe-footer");
    if (footerElements.length === 1) {
      footerElements[0].setAttribute("contact-url", JSON.stringify(this.bfcConfigurationService.configuration.globalContactURL));
    } else {
      throw (new Error("too many or no bfe-footer element found"));
    }
  }
}
