import { Component } from "@angular/core";
import { ActivatedRoute } from "@angular/router";

@Component({
  selector: "app-component",
  styleUrls: ["app.component.scss"],
  templateUrl: "./app.component.html",
})
export class AppComponent {

  public location: string = "";

  constructor(private activatedRoute: ActivatedRoute) {
    if (!!activatedRoute) {
      activatedRoute.queryParams.subscribe(params => {
        if (!!params.location) {
          this.location = params.location;
        }
      });
    }
  }
}
